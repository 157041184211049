import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { Layout } from "../../components/layout";

const IndexPage = () => {
  return (
    <>
      <Layout title="Dogs with Job">
        <main className="container justify">
          <h1>Dogs with Job</h1>
          <section className="row my-3">
            <article className="col-md-6">
              <h3>Enzo - Oberon f. Golden Eyes of Munroe</h3>
              <p>
                Enzo ( Oberon f. Golden Eyes of Munroe) wird Diabetiker Warnhund. Er macht seine Sache prima.
              </p>
            </article>
            <StaticImage
              loading="lazy"
              layout="fullWidth"
              className="col-md-6"
              objectFit="contain"
              src="../../images/dog-with-job/job6.jpg"
              alt="ad"
            />
          </section>
          <section className="row my-3">
            <article className="col-md-6">
              <h3>Keylam - Jacob Tyron f. Golden Eyes of Munroe</h3>
              <p>
                Keylam - Jacob Tyron f. Golden Eyes of Munroe (Erinderry Night
                of Delight/In the Mood of Redpine) hat mit Thomas den II. Teil
                der Rettungshundestaffel erfolgreich bestanden. Herzlichen
                Glückwunsch an Fam. Grundhöfer!! Tolle Leistung!
              </p>
            </article>
            <StaticImage
              loading="lazy"
              layout="fullWidth"
              className="col-md-6"
              objectFit="contain"
              src="../../images/dog-with-job/job1.jpg"
              alt="ad"
            />
          </section>
          <section className="row my-3">
            <article className="col-md-6">
              <h3>
                Fresh Giddyap ( Beppo ) ( Jackralee Serge/ In the Mood of
                Redpine)
              </h3>
              <p>geprüfter und zertifizierter Schulhund. in Bayern</p>
              <p>
                Mehr erfahren Sie
                <a href="http://www.anni-braun-schule.de/schulprofil/neigungsgruppen.html">
                  {" "}
                  hier...
                </a>
                Danach auf 'Unser Schulhund names Beppo' klicken.
              </p>
            </article>
            <StaticImage
              loading="lazy"
              layout="fullWidth"
              className="col-md-6"
              objectFit="contain"
              src="../../images/dog-with-job/job2.jpg"
              alt="ad"
            />
          </section>
          <section className="row my-3">
            <article className="col-md-6">
              <h3>
                Henry Monaghan (Henry), (Champion Dainty´s Foreign Currency / In
                the Mood of Redpine)
              </h3>
              <p>
                Henry beginnt seine Ausbildung zum Therapiebegleithund im
                Gesundheitswesen. Er wird dann, zusammen mit seinem Frauchen
                Julia, bei den Murgtal-Wohngemeinschaften mit Menschen mit
                Beeinträchtigungen arbeiten. Info über die zertifizierte
                Ausbildung finden Sie unter{" "}
                <a href="http://www.mittt.de/">www.mittt.de</a>
              </p>
            </article>
            <StaticImage
              loading="lazy"
              layout="fullWidth"
              className="col-md-6"
              objectFit="contain"
              src="../../images/dog-with-job/job3.jpg"
              alt="ad"
            />
          </section>
          <section className="row my-3">
            <article className="col-md-6">
              <h3>
                Joseph Peregrine ( Mennie ), ( Multi. Champion Erinderry Night
                of Delight / In the Mood of Redpine )
              </h3>
              <p>Mennie beginnt ebenfalls seine Ausbildung als Therapiehund.</p>
              <p>
                Die Arbeit mit den Vierbeinern bereitet den Mitarbeitern der
                Murgtalwerkstätten sichtlich große Freude. Golden Retriever
                Menni (rechts) wird zum Therapiehund ausgebildet.
              </p>
              <p>
                Ich bin mächtig stolz auf meine eine drei Jungs und ihre
                Frauchen bzw. Herrchen Simone Roscher ( Beppo), Julia Wunsch (
                Henry) und Frank Mittermeier ( Mennie ). Meinen besonderen Dank
                diesen tollen Menschen, die so viel Zeit und Energie in die
                Ausbildung stecken. Eine wahrlich großartige Aufgabe für Mensch
                und Hund
              </p>
            </article>
            <article className="col-md-6 center">
              <StaticImage
                loading="lazy"
                layout="fullWidth"
                src="../../images/dog-with-job/job4.jpg"
                alt="ad"
                objectFit="contain"
              />
              <StaticImage
                loading="lazy"
                layout="fullWidth"
                src="../../images/dog-with-job/job5.jpg"
                alt="ad"
                objectFit="contain"
              />
            </article>
          </section>
        </main>
      </Layout>
    </>
  );
};

export default IndexPage;
